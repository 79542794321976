import React, { useEffect, useState } from "react";
import "./Chats.css";
import { Avatar } from "@material-ui/core";
import { database } from "../firebase";
import { Link } from "react-router-dom";

function Chats({ addChat, id, name }) {
  const [avatars, setavatars] = useState("");
  const [messages, setmessages] = useState("");

  useEffect(() => {
    setavatars(Math.floor(Math.random() * 5000));
  }, []);

  useEffect(() => {}, [id]);

  const addnewChat = () => {
    const chatName = prompt("Enter A name");
  };

  return !addChat ? (
    <Link to={`/rooms/${id}`}>
      <div className="chats">
        <Avatar src={`/iverselogo.jpeg`} style={{ " object-fit": "contain" }} />
        <div className="chats__info">
          <h3>{name}</h3>
          <p>{messages[0]?.message}</p>
        </div>
      </div>
    </Link>
  ) : (
    <div onClick={addnewChat} className="chats">
      <h2>Add New Chat</h2>
    </div>
  );
}

export default Chats;
