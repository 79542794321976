import React, { useState, useEffect } from "react";
import firebase from "firebase";
import "./ChatRoom.css";
import { Avatar } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import SendIcon from "@material-ui/icons/Send";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import MicIcon from "@material-ui/icons/Mic";
import { useParams } from "react-router-dom";
import { database } from "../firebase";
import { useStateValue } from "../StateProvider";
function ChatRoom() {
  const [input, setinput] = useState("");
  const { roomid } = useParams();
  const [roomname, setroomname] = useState("");
  const [message, setmessages] = useState([]);
  const [prompt, setPrompt] = useState("");
  const [{ user }, dispatch] = useStateValue();
  useEffect(() => {
    setPrompt(
      "The following dialogue is a conversation with an AI assistant for imagikids brand on their site. The assistant is helpful, creative, clever, and very friendly. \
      The assistant tries to find out the needs of the customer and helps the customer in order to assist the customer to process checkout correctly. The assistant is a smart seller. He presents himself and helps the customer in his purchase. \
      imagikids is a brand which sells clothes for kids. In fact, they sell clothes with cartoons character from Marvel (avengers, iron man..), Disney (cars, lion king, toy story..) , Nickelodeon (sponge bob..), Warner Bros (batman, tom & jerry), Lucasfilm (star wars) and Hasbro (peppa pig, my little pony..). \
      imagikids sells tees, shorts, skirts, tops, pants, dresses, hoodies, sweaters, coveralls, costumes and swimwear. \
      The price of the clothes depends on the size, the model and the selected cartoon. \
      The size of the clothes starts from new born and 2T-20. imagikids ships its orders only in United States.\n"
    );
  }, []);
  useEffect(() => {
    if (message.length === 0 || message[message.length - 1].name === "Assistant")
      return;
    makeRequest(input);
    setinput("");
  }, [message]);

  const SendMessage = (event) => {
    event.preventDefault();
    const toSave = [
      ...message,
      {
        name: "Customer",
        message: input,
      },
    ];
    setPrompt(`${prompt}Customer:${input}\nAssistant:`);
    setmessages(toSave);
  };

  const makeRequest = (question) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer 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"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      model: "text-davinci-002",
      prompt,
      temperature: 0.75,
      max_tokens: 150,
      top_p: 1,
      frequency_penalty: 1.5,
      presence_penalty: 0.6,
      stop: ["Customer:", "Assistant"],
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.openai.com/v1/completions", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (!result?.choices?.length > 0) return;
        setmessages([
          ...message,
          {
            name: "Assistant",
            message: result?.choices[0]?.text,
            date: new Date(),
          },
        ]);
        setPrompt(`${prompt}${result?.choices[0]?.text}`);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className="chatroom">
      <div className="chatroom__header">
        <Avatar />
        <div className="chatroom__headerinfoleft">
          <h3>{roomname}</h3>
          {message.length > 0 &&
            message[message.length - 1]?.date &&
            "last seen :" +
              new Date(message[message.length - 1]?.date).toUTCString()}
          <p></p>
        </div>
        {/* <div className="chatroom__header__inforight">
          <SearchIcon />
          <AttachFileIcon />
          <MoreVertIcon />
        </div> */}
      </div>
      <div className="chatroom__body">
        {message.map((message, idex) => (
          <p
            key={idex}
            className={`chatroom__message ${
              message.name === "Assistant" && `chatroom__messagerecierver`
            }`}
          >
            <span className="chatroom__username">{message.name}</span>
            {message.message}
            {/* <span className="chatroom__messagetimestamp ">
              {new Date().toUTCString()}
            </span> */}
          </p>
        ))}
      </div>
      <div className="chatroom__footer">
        {/* <SentimentVerySatisfiedIcon /> */}
        <form>
          <input
            value={input}
            onChange={(event) => setinput(event.target.value)}
            placeholder="Type your message here"
          />
          <button onClick={SendMessage} type="submit">
            <SendIcon />
          </button>
        </form>
        {/* <MicIcon /> */}
      </div>
    </div>
  );
}

export default ChatRoom;
